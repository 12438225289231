<template>
<div class=" pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">
   
    <div class="flex h-full max-w-full" :class="isLoading ? 'hidden' : 'block'">
        <div class="flex sm:flex-row w-full flex-col h-full">
            <div class="flex flex-col w-full sm:mt-0  ">
                <div class="inline-flex justify-between">
                    <div class="mb-2">
                      <p class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des abonnements crées</p>
                    </div>
                    <div></div>
                </div>
                <div class="border rounded-lg py-3 h-full">
                  
                  <div v-if="!searchstate" class="mb-4 px-4 w-full flex sm:flex-row flex-col justify-center">
        
                    <div class="sm:w-1/5 w-full m-1">
                        <el-select @change="searchSubscription" v-model="onlineAndOutSubscribe" placeholder="Abonnements En Ligne & Guichet" class="w-full">
                            <el-option v-for="item in onlineAndOut" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sm:w-1/5 w-full m-1">
                        <el-select @change="searchSubscription" v-model="activeAndNonActiveSubscribe" placeholder="Abonnements Actifs & Non-Actifs" class="w-full">
                            <el-option v-for="item in activeAndNonActive" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sm:w-1/5 w-full m-1">
                        <el-select @change="searchSubscription" v-model="subcriptionType" placeholder="Types d'abonnements" class="w-full">
                            <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div :class="(subcriptionType == 'yearsub') ? 'block' : 'hidden'" class="sm:w-1/5 w-full m-1">
                        <el-select @change="searchSubscription"  v-model="subcriptionYear" placeholder="Année de l'abonnements" class="w-full">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div @click="searchstate = true" class="relative sm:block hidden sm:w-20 w-full">
                      <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                      </svg>
                      
                    </div>
                  </div>
                  <div v-else class="mb-4 px-4 w-full flex sm:flex-row flex-col justify-center">
                    <div class="relative sm:w-48 w-full">
                      <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                      </svg>
                      <input
                        type="search"
                        v-model="InputValue"
                        @keyup.enter="Search()"
                        placeholder="Recherche par ID"
                        class="focus:border-light-blue-500 focus:ring-1 focus:ring-red-400 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-300 rounded-md py-2 pl-10 "
                      >
                  </div>
                  <div @click="CancelSearch()" class=" my-auto mx-3">
                    <svg version="1.1" id="Layer_1" width="20" height="20" viewBox="0 0 128 128" enable-background="new 0 0 128 128" xml:space="preserve">  <g>  	<g>
  		                <path fill="#B0BEC5" d="M64,0C28.656,0,0,28.656,0,64s28.656,64,64,64s64-28.656,64-64S99.344,0,64,0z M64,120		C33.125,120,8,94.875,8,64S33.125,8,64,8s56,25.125,56,56S94.875,120,64,120z"/>  	</g>  </g>  <g>  	<g>  		<path fill="#F44336" d="M75.313,64l16.969-16.969c3.125-3.125,3.125-8.195,0-11.313c-3.117-3.125-8.188-3.125-11.313,0L64,52.688			L47.031,35.719c-3.125-3.125-8.195-3.125-11.313,0c-3.125,3.117-3.125,8.188,0,11.313L52.688,64L35.719,80.969			c-3.125,3.125-3.125,8.195,0,11.313c3.117,3.125,8.188,3.125,11.313,0L64,75.313l16.969,16.969c3.125,3.125,8.195,3.125,11.313,0			c3.125-3.117,3.125-8.188,0-11.313L75.313,64z"/>  	</g>  </g>
                    </svg>
                  </div>
                  </div>
                  
                  
                  <div :class="isLOadingQuestions ? 'block' : 'hidden'" class="flex items-center justify-center sm:mt-32 w-full">
                    <!--<div class="fulfilling-square-spinner">
                      <div class="spinner-inner"></div>
                    </div>-->
                    <div class="loading">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </div>

                  <div :class="isLOadingQuestions ? 'hidden' : 'block'">
                    <div v-if="subsciptions.length == 0">
                      <div class="flex justify-center items-center h-full p-5 sm:mt-12">
                        <el-result
                          icon="info"
                          title="Aucun abonnement"
                          subTitle="Vous n'avez aucun abonnement"
                        >
                        </el-result>
                      </div>
                    </div>

                    <div v-else>
                      <div class="mb-3">
   
                        <table class="table-auto hidden sm:inline-table">
                          <thead>
                            <tr class="bg-white border-b border-t text-gray-600 uppercase  text-sm leading-normal">
                              <th class="py-3 px-2 text-center">ID </th>
                              <th class="py-3 px-2 text-center">Code abonnement</th>
                              <th class="py-3 px-2 text-center">Type d'abonnement</th>
                              <th class="py-3 px-2 text-center">État d'abonnement</th>
                              <th class="py-3 px-2 text-center">Date d'expiration</th>
                              <th class="py-3 px-2 text-center">Module d'abonnement</th>
                              <th class="py-3 px-2 text-center">Date creartion</th>
                              <th class="py-3 px-2 text-center">Action</th>
                            </tr>
                          </thead>
                        <tbody  class="text-gray-600 text-sm font-light">
                            <tr v-for="item in subsciptions" :key="item.id" class="border-b border-gray-200 hover:bg-gray-100">
                              <td class="py-3 px-2 text-center whitespace-nowrap">
                                      <div class="flex justify-center items-center">
                                          <span class="font-medium">{{ item.id }}</span>
                                      </div>
                                  </td>  
                              <td class="py-3 px-2 text-center whitespace-nowrap">
                                    <div class="flex justify-center items-center">
                                        <span class="font-medium">{{ item.Activation_code }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-2 text-center">
                                    <div class="flex justify-center items-center">
                                        <span v-if="item.type === 'online'">En ligne</span>
                                        <span v-else>Guichet</span>
                                    </div>
                                </td>
                                <td class="py-3 px-2 text-center">
                                    <div class="flex justify-center items-center">
                                        <span v-if="item.state== 'active'" class="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs">Actif</span>
                                        <span v-if="item.state== 'available'" class="bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full text-xs">Non-Actif</span>
                                        <span v-if="item.state== 'expired'" class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs">Expiré</span>
                                        <span v-if="item.state== 'canceled'" class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs">Annulé</span>
                                    </div>
                                </td>
                                <td class="py-3 px-2 text-center">
                                    <div class="flex justify-center items-center">
                                        <span v-if="item.expiration_date == null">Pas encore</span>
                                        <span v-else>{{item.expiration_date}}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-2 text-center">
                                  <span v-if="item.year == 0"> Résidanat </span>    
                                  <span v-else>{{ item.year }} Année</span> 
                                </td>
                                <td class="py-3 px-2 text-center">
                                  <div class="flex justify-center items-center">
                                    <span >{{ dateTime(item.created_at)  }}</span>
                                  </div>
                                </td>
                                <td class="py-3 px-2 text-center">
                                  <div v-if="item.state == 'active'" disabled>
                                    <el-popover
                                          :width="300"
                                          popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                                        >
                                          <template #reference>
                                            <el-avatar v-if="item.profil_image != null " :size="35" :src="item.profil_image" />
                                            <el-avatar v-else :size="35" src="/avatar_profile.jpg" />
                                          </template>
                                          <template #default>
                                            <div
                                              class="demo-rich-conent"
                                              style="display: flex; gap: 16px; flex-direction: row"
                                            >
                                              <el-avatar v-if="item.profil_image != null" :size="50" :src="item.profil_image" />
                                              <el-avatar v-else :size="50"  src="/avatar_profile.jpg" />
                                              
                                              <div>
                                                <p
                                                  class="demo-rich-content__name text-lg"
                                                  style="margin: 0; font-weight: 500"
                                                >
                                                  {{ item.lastname }} {{ item.firstname }}
                                                </p>
                                                <p
                                                  class="demo-rich-content__mention text-xs "
                                                  style=" color: var(--el-color-info)"
                                                >
                                                  {{ item.email }}
                                                </p>
                                                 <div class="flex justify-center items-center">
                                                  <span class="w-40 text-center mt-1 bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs"> Activé le : {{ dateTime(item.activation_date) }}</span>
                                                    
                                                 </div>
                                              </div>
                                             
                                            </div>
                                          </template>
                                        </el-popover>
    
                                </div>
                                  <el-popconfirm v-else @confirm="confirmEvent(item.id , item.year)" confirm-button-text="Oui" cancel-button-text="Non"
                                    confirm-button-type="danger" :icon="InfoFilled" icon-color="#f87171" title="Voulez vous vraiment supprimer cet abonnement ?">
                                    <template #reference>
                                      <button
                                        class="pt-2 pb-1 px-2  text-white font-normal text-xs bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                                        <el-icon>
                                          <Delete />
                                        </el-icon>
                                      </button>
                                    </template>
                                  </el-popconfirm>
                                </td>
                            </tr>          
                        </tbody>
                    </table>

                      </div>
                      <ul v-if="lastp != 1" class="relative z-0 inline-flex rounded-md w-full justify-center ">
                        <li>
                          <button :disabled="currentp === 1"
                            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            @click.prevent="prevPage">
                            <span class="sr-only">Previous</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                              aria-hidden="true">
                              <path fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd" />
                            </svg>
                          </button>
                        </li>
                        <li class="flex flex-row">
                          <button class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-700"
                            
                            @click.prevent="refine(1)">
                            1
                          </button>
                          <input :max="lastp" @input="refine(currentp)" class="w-14 hover:bg-gray-50 relative inline-flex text-center items-center border text-sm font-medium bg-white border-gray-300 text-gray-700" v-model="currentp" placeholder="page"  />
                          <button class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-700"
                            @click.prevent="refine(lastp)">
                            {{ lastp }}
                          </button>
                        </li>
                      
                        <li>
                          <button :disabled="currentp === lastp" 
                            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            @click.prevent="nextPage">
                            <span class="sr-only">Next</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                              aria-hidden="true">
                              <path fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>

            </div>
        </div>
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

</div>

</template>

<script>

import { ElMessage } from 'element-plus'
import { h } from 'vue'
import { WarningFilled, Delete } from '@element-plus/icons';
import moment from 'moment';
import axiosIns from "../../plugins/axios";

export default {
    components: {
    WarningFilled,
    Delete
    },
    data () {
        return {
            searchstate : false,
            InputValue: "",
            lastp : null,
            nextp :null,
            currentp :null,
            isLoading :false,
            isLOadingQuestions: false,
            subsciptions : [],
            subcriptionType:'all',
            subcriptionYear:'all',
            activeAndNonActiveSubscribe:"all",
            onlineAndOutSubscribe : "all",
            options: [
                {
                  value: '1',
                  label: '1 Année',
                },
                {
                  value: '2',
                  label: '2 Année',
                },
                {
                  value: '3',
                  label: '3 Année',
                },
                {
                  value: '4',
                  label: '4 Année',
                },
                {
                  value: '5',
                  label: '5 Année',
                },
                {
                  value: '6',
                  label: '6 Année',
                },
                {
                  value: 'noyear',
                  label: 'Aucune',
                },


            ],

            types: [
                {
                  value: 'allsub',
                  label: 'Résidanat',
                },
                {
                  value: 'yearsub',
                  label: 'Abonnement annuel',
                },
                {
                  value: 'all',
                  label: 'Tous',
                },
            ],

             onlineAndOut: [
                {
                  value: 'online',
                  label: 'En ligne',
                },
                {
                  value: 'out',
                  label: 'Guichet',
                },
                {
                  value: 'all',
                  label: 'Tous',
                },
            ],

            activeAndNonActive: [
                {
                  value: 'active',
                  label: 'Abonnement actif',
                },
                {
                  value: 'available',
                  label: 'Abonnement non-actif',
                },
                {
                  value: 'expired',
                  label: 'Expiré',
                },
                {
                  value: 'canceled',
                  label: 'Annulé',
                },
                {
                  value: 'all',
                  label: 'Tous',
                },
            ]
        }
    },

    mounted () {
      axiosIns.get("/getfiltredsubscribes/all/all/all")
        .then(({data}) => {
          this.subsciptions = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.currentp = data.result.current_page;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    methods: {
      Search() {
        this.subcriptionType ='all'
        this.subcriptionYear = 'all'
        this.activeAndNonActiveSubscribe = "all"
        this.onlineAndOutSubscribe  = "all"
      if (this.InputValue !== '') {
          axiosIns.get("/getsearchedsubscribes/" + this.InputValue)
            .then(({ data }) => {
              this.subsciptions = data.subscribes;
              this.lastp = 1;
              this.currentp = 1;
              this.isLoading = false;
            })
            .catch(() => {
              this.errorm();
              this.isLoading = false;
            });
        
      }else{
        axiosIns.get("/getfiltredsubscribes/all/all/all")
          .then(({ data }) => {
            this.subsciptions = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.currentp = data.result.current_page;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }

    },
    CancelSearch(){
      this.subcriptionType = 'all'
      this.subcriptionYear = 'all'
      this.activeAndNonActiveSubscribe = "all"
      this.onlineAndOutSubscribe = "all"
      this.searchstate = false
      this.InputValue = ""
      axiosIns.get("/getfiltredsubscribes/all/all/all")
        .then(({ data }) => {
          this.subsciptions = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.currentp = data.result.current_page;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
      dateTime(value) {
        return moment(value).format('YYYY-MM-DD');
      },
      
      confirmEvent(id, title) {
        var identifiant = id;
        
        axiosIns.delete("/subscribe/" + id)
          .then(() => {
            this.sucess(title);
            this.subsciptions = this.subsciptions.filter((tab) => tab.id !== identifiant);
          })
          .catch(() => {
            this.errorm();
          });
      },
      searchSubscription(){

        if(this.subcriptionType=="allsub"){
          this.subcriptionYear = '0';
        }else if(this.subcriptionType=="all"){
          this.subcriptionYear = "all";
        }else if(this.subcriptionType=="yearsub" &&(this.subcriptionYear === '0' || this.subcriptionYear === "all" )){
          this.subcriptionYear = 'noyear';
        }

        axiosIns.get("/getfiltredsubscribes/"+this.onlineAndOutSubscribe+"/"+this.activeAndNonActiveSubscribe+"/"+this.subcriptionYear)
        .then(({data}) => {
          this.subsciptions = data.result.data;
          this.lastp = data.result.last_page;
          this.nextp = data.result.next_page_url;
          this.currentp = data.result.current_page;
        })
        .catch(() => {
          this.isLoading = false;
        });
      },

      nextPage() {
        axiosIns.get(this.nextp)
          .then(({ data }) => {
            this.subsciptions = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.prevp = data.result.prev_page_url;
            this.currentp = data.result.current_page;
          })
          .catch(() => {
            this.errorm();
          });
      },

      prevPage() {
        
        axiosIns.get(this.prevp)
          .then(({ data }) => {
            this.subsciptions = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.prevp = data.result.prev_page_url;
            this.currentp = data.result.current_page;
          })
          .catch(() => {
            this.errorm();
          });
      },

      refine(page) {
        axiosIns.get("/getfiltredsubscribes/" + this.onlineAndOutSubscribe + "/" + this.activeAndNonActiveSubscribe + "/" + this.subcriptionYear + "?page=" + page)
          .then(({ data }) => {
            this.subsciptions = data.result.data;
            this.lastp = data.result.last_page;
            this.nextp = data.result.next_page_url;
            this.prevp = data.result.prev_page_url;
            this.currentp = data.result.current_page;
          })
          .catch(() => {
            this.errorm();
          });
      },

      
    },
  
    computed: {
      isToken() {
        return this.$store.getters.get_token;
      },
     
    },

  setup() {
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }
    const sucess = (event) => {
      var msg;
      if(event == 0){
        msg ="Résidanat"
      }else{
        msg = event + " Année"
      }
      ElMessage({
        message: h('p', { style: 'color: #65a30d' }, [

          h('span', null, "L\'abonnement "),
          h('span', { style: 'font-weight: bold' }, msg),
          h('span', null, ' a été supprimé avec succès'),
        ]),
        type: 'success',
      })
    }

    return { errorm, sucess }
  }
     
   
}
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>